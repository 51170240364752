import React from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Avatar,
  LinearProgress,
  Grow,
  CircularProgress,
  Skeleton,
  ButtonGroup,
  Grid,
  Card,
  Popper,
  Paper,
  ClickAwayListener,
  Button,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import {
  ExpandMore,
  SpeedOutlined,
  InfoOutlined,
  Speed,
  SpeedRounded,
  ContentCopyOutlined,
  CheckCircleOutlineOutlined,
  Download,
  ThumbUpSharp,
  ThumbDown,
  ThumbUp,
  ThumbUpAltOutlined,
  SentimentNeutral,
  SentimentSatisfied,
  SentimentVerySatisfiedOutlined,
  SentimentSatisfiedAltOutlined,
  SentimentDissatisfiedOutlined,
  SentimentNeutralOutlined,
  DataArrayOutlined,
  BarChartOutlined,
  ForumOutlined,
  CommentOutlined,
  GroupWorkOutlined,
  GroupsOutlined,
  QuestionAnswerOutlined,
  GavelOutlined,
  HandshakeOutlined,
  TaskAltOutlined,
  HighlightOffOutlined,
  PublicOutlined,
  AnalyticsOutlined,
  ExpandMoreOutlined,
  ExpandLessOutlined,
  SentimentVeryDissatisfiedOutlined,
  ExpandRounded,
  MoreVert,
  MoreVertOutlined,
  MoreHorizOutlined,
  Mood,
  PriorityHigh,
  HowToVoteOutlined,
  Stars,
} from "@mui/icons-material";
import html2canvas from "html2canvas";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { CheckCircleOutline } from "@mui/icons-material";

const VibeCheckSection = React.memo(function VibeCheckSection(props) {
  const {
    selectedPostData,
    exportVibeScoreSection,
    screenshot,
    selectedGroups,
    fullCommentData,
    searchedComments,
    selectedComments,
    isMobile,
    fullTable,
    searched,
    selected,
    quickView
  } = props;
  const [hidden, setHidden] = React.useState(false);
  const [vibeCheck, setVibeCheck] = React.useState(null);
  const [exporting, setExporting] = React.useState(false);
  const [hovered, setHovered] = React.useState(false);
  const [copied, setCopied] = React.useState(false);
  const [openExplainer, setOpenExplainer] = React.useState(false);
  const vibeCheckScreenshotRef = React.useRef(null);
  const [expanded, setExpanded] = React.useState(false);
  const explainerButtonRef = React.useRef(null);
  const [openImage, setOpenImage] = React.useState(false); // State to manage image visibility

  const analyticsDefinitionText = 
  
  `Vibe Score:\n
  The Vibe Score is Siftsy's proprietary metric that measures overall audience reception and interaction in comment sections on a scale of 1-10.
\n
Calculation\n

The score is calculated by averaging three key factors:
\n
-Sentiment\n
-Relevance\n
-Consensus of comments\n

Interpretation\n

-Scores closer to 10: Indicate a great atmosphere with a receptive, supportive audience\n
-Scores closer to 1: Suggest hostility and negative opinions/interactions\n

Application to Sponsored Content\n

For sponsored content, a comment section with a Vibe Score above 5 generally indicates:\n

-Positive reception\n
-Brand safety\n
-Increased brand awareness\n
-Potential for fostering purchase consideration and intent\n\n

Scoring Breakdown:\n
Sentiment (1-10)\n

Measures the emotional tone of comments from negative to neutral to positive.\n

Relevance (1-10)\n

Assesses how closely comments relate to the original post content:\n

-Low scores: Completely off-topic\n
-High scores: Directly responding to or paraphrasing the content\n

Consensus (1-10)\n

Evaluates how commenters interact with each other:\n

-Low scores: Hostile arguing\n
-High scores: Complete agreement and mutual support\n\n

Evaluation:\n

Siftsy employs AI technology and large language models to rank and score comments based on the above definitions, aiming for a Vibe Score accuracy within ±1 point of error.

`;

  const maxPercentage = (data) => {
    const percentages = data.map((item) => parseFloat(item.replace("%", "")));
    const maxIndex = percentages.indexOf(Math.max(...percentages));
    return {
      value: !isNaN(percentages[maxIndex])
        ? percentages[maxIndex] + "%"
        : "N/A",
      index: maxIndex,
    };
  };

  React.useEffect(() => {
    document.getElementById("vibe-check-section") &&
      document
        .getElementById("vibe-check-section")
        .addEventListener("mouseover", () => {
          setHovered(true);
        });

    document.getElementById("vibe-check-section") &&
      document
        .getElementById("vibe-check-section")
        .addEventListener("mouseout", () => {
          setHovered(false);
        });

    return () => {
      document.getElementById("vibe-check-section") &&
        document
          .getElementById("vibe-check-section")
          .removeEventListener("mouseover", () => {
            setHovered(true);
          });

      document.getElementById("vibe-check-section") &&
        document
          .getElementById("vibe-check-section")
          .removeEventListener("mouseout", () => {
            setHovered(false);
          });
    };
  }, []);

  const handleExportVibeCheck = async () => {
    //delay .5s
    setExporting(true);
    await new Promise((resolve) => setTimeout(resolve, 500));

    if (vibeCheckScreenshotRef.current) {
      // vibeCheckScreenshotRef.current.style.display = "flex";
      // deep copy metricsRef.current element
      const style = document.createElement("style");
      document.head.appendChild(style);
      style.sheet?.insertRule(
        "body > div:last-child img { display: inline-block; }"
      );
      let canvas = await html2canvas(vibeCheckScreenshotRef.current, {
        useCORS: true,
        allowTaint: true,
        logging: true,
        backgroundColor: null,
        scrollX: 0,
        scrollY: 0,
        scale: 4,
        letterRendering: true,
      });

      const link = document.createElement("a");
      link.href = canvas.toDataURL();
      link.download = `siftsy_vibe_check.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      style.remove();

      //   vibeCheckScreenshotRef.current.style.display = "none";
    }

    setExporting(false);
  };

  const handleCopyMetrics = async () => {
    setCopied(true);
    let vibeCheckText = "";

    if (selectedPostData && selectedPostData.length > 0) {
      vibeCheckText =
        `Vibe Score: ${vibeCheck.vibeScore.toFixed(1)}\n\n` +
        `Sentiment Score: ${vibeCheck.sentimentData.sentimentScore.toFixed(
          1
        )}/10\n` +
        "Positive: " +
        vibeCheck.sentimentData.positivePercentage +
        "\n" +
        "Neutral: " +
        vibeCheck.sentimentData.neutralPercentage +
        "\n" +
        "Negative: " +
        vibeCheck.sentimentData.negativePercentage +
        "\n\n" +
        `Relevance Score: ${vibeCheck.relevanceData.relevanceScore.toFixed(
          1
        )}/10\n` +
        "On-Topic: " +
        vibeCheck.relevanceData.positivePercentage +
        "\n" +
        "General: " +
        vibeCheck.relevanceData.neutralPercentage +
        "\n" +
        "Off-Topic: " +
        vibeCheck.relevanceData.negativePercentage +
        "\n\n" +
        `Consensus Score: ${vibeCheck.consensusData.consensusScore.toFixed(
          1
        )}/10\n` +
        "Agreement: " +
        vibeCheck.consensusData.positivePercentage +
        "\n" +
        "Mixed: " +
        vibeCheck.consensusData.neutralPercentage +
        "\n" +
        "Arguing: " +
        vibeCheck.consensusData.negativePercentage +
        "\n\n";
    }

    //remove lines with null
    vibeCheckText = vibeCheckText.replace(/null/g, "n/a");

    navigator.clipboard.writeText(vibeCheckText);

    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  React.useEffect(() => {
    if (selectedPostData && selectedPostData.length > 0) {
      if (selected) {
        refreshVibeCheck(selectedComments);
      } 
      else if (searched) {
        refreshVibeCheck(searchedComments);
      }
      else {
      refreshVibeCheck(fullCommentData);
      }
    }
  }, [selectedPostData, fullCommentData, searchedComments, selectedComments, selected, searched]);

  const refreshVibeCheck = React.useCallback(async (commentData) => {
    const vibeCheck = {
      vibeScore:
      (searched || selected) ? 
      commentData && commentData.length > 0
      ? (commentData
        .filter((comment) =>
          comment.comment.scores &&
          comment.comment.scores.consensusScore != null &&
          comment.comment.scores.sentimentScore != null &&
          comment.comment.scores.relevanceScore != null
        )
        .reduce((sum, comment) => 
          sum + 
          (comment.comment.scores.consensusScore + 
           comment.comment.scores.sentimentScore + 
           comment.comment.scores.relevanceScore) / 3, 0) /
        commentData.filter((comment) =>
          comment.comment.scores &&
          comment.comment.scores.consensusScore != null &&
          comment.comment.scores.sentimentScore != null &&
          comment.comment.scores.relevanceScore != null
        ).length)  
      : null
      :
        selectedPostData
          .filter((post) => post.data.totalComments > 0)
          .map((post) =>
            post.reports &&
            post.reports[0] &&
            post.reports[0].vibeCheck &&
            post.reports[0].vibeCheck.vibeScore
              ? parseFloat(post.reports[0].vibeCheck.vibeScore)
              : 0
          )
          .reduce((a, b) => a + b, 0) /
        selectedPostData.filter((post) => post.data.totalComments > 0).length,

      consensusData: {
        consensusScore:
        (searched || selected) ? 
        commentData && commentData.length > 0
        ? (commentData
          .filter((comment) =>
            comment.comment.scores &&
            comment.comment.scores.consensusScore != null
          )
          .reduce((sum, comment) => sum + comment.comment.scores.relevanceScore, 0) /
          commentData.filter((comment) =>
            comment.comment.scores &&
            comment.comment.scores.consensusScore != null
          ).length) 
        : null
        :
          selectedPostData
            .filter((post) => post.data.totalComments > 0)
            .map((post) =>
              post.reports &&
              post.reports[0] &&
              post.reports[0].vibeCheck &&
              post.reports[0].vibeCheck.consensusData
                ? parseFloat(
                    post.reports[0].vibeCheck.consensusData.consensusScore
                  )
                : 0
            )
            .reduce((a, b) => a + b, 0) /
          selectedPostData.filter((post) => post.data.totalComments > 0).length,
        positivePercentage:
          commentData && commentData.length > 0
            ? (
                (commentData
                  .filter((comment) =>
                    comment.comment.scores &&
                    comment.comment.scores.consensusScore
                      ? comment.comment.scores.consensusScore
                      : null
                  )
                  .filter(
                    (comment) => comment.comment.scores.consensusScore > 7
                  ).length /
                  commentData.filter((comment) =>
                    comment.comment.scores &&
                    comment.comment.scores.consensusScore
                      ? comment.comment.scores.consensusScore
                      : null
                  ).length) *
                100
              ).toFixed(1) + "%"
            : null,
        neutralPercentage:
          commentData && commentData.length > 0
            ? (
                (commentData
                  .filter((comment) =>
                    comment.comment.scores &&
                    comment.comment.scores.consensusScore
                      ? comment.comment.scores.consensusScore
                      : null
                  )
                  .filter(
                    (comment) =>
                      comment.comment.scores.consensusScore >= 3 &&
                      comment.comment.scores.consensusScore <= 7
                  ).length /
                  commentData.filter((comment) =>
                    comment.comment.scores &&
                    comment.comment.scores.consensusScore
                      ? comment.comment.scores.consensusScore
                      : null
                  ).length) *
                100
              ).toFixed(1) + "%"
            : null,
        negativePercentage:
          commentData && commentData.length > 0
            ? (
                (commentData
                  .filter((comment) =>
                    comment.comment.scores &&
                    comment.comment.scores.consensusScore
                      ? comment.comment.scores.consensusScore
                      : null
                  )
                  .filter(
                    (comment) => comment.comment.scores.consensusScore < 3
                  ).length /
                  commentData.filter((comment) =>
                    comment.comment.scores &&
                    comment.comment.scores.consensusScore
                      ? comment.comment.scores.consensusScore
                      : null
                  ).length) *
                100
              ).toFixed(1) + "%"
            : null,
      },
      relevanceData: {
        relevanceScore:
                (searched || selected) ? 
        commentData && commentData.length > 0
        ? (commentData
          .filter((comment) =>
            comment.comment.scores &&
            comment.comment.scores.relevanceScore != null
          )
          .reduce((sum, comment) => sum + comment.comment.scores.relevanceScore, 0) /
          commentData.filter((comment) =>
            comment.comment.scores &&
            comment.comment.scores.relevanceScore != null
          ).length) 
        : null
        :
          selectedPostData
            .filter((post) => post.data.totalComments > 0)
            .map((post) =>
              post.reports &&
              post.reports[0] &&
              post.reports[0].vibeCheck &&
              post.reports[0].vibeCheck.relevanceData
                ? parseFloat(
                    post.reports[0].vibeCheck.relevanceData.relevanceScore
                  )
                : 0
            )
            .reduce((a, b) => a + b, 0) /
          selectedPostData.filter((post) => post.data.totalComments > 0).length,
        positivePercentage:
          commentData && commentData.length > 0
            ? (
                (commentData
                  .filter((comment) =>
                    comment.comment.scores &&
                    comment.comment.scores.relevanceScore
                      ? comment.comment.scores.relevanceScore
                      : null
                  )
                  .filter(
                    (comment) => comment.comment.scores.relevanceScore > 7
                  ).length /
                  commentData.filter((comment) =>
                    comment.comment.scores &&
                    comment.comment.scores.relevanceScore
                      ? comment.comment.scores.relevanceScore
                      : null
                  ).length) *
                100
              ).toFixed(1) + "%"
            : null,
        neutralPercentage:
          commentData && commentData.length > 0
            ? (
                (commentData
                  .filter((comment) =>
                    comment.comment.scores &&
                    comment.comment.scores.relevanceScore
                      ? comment.comment.scores.relevanceScore
                      : null
                  )
                  .filter(
                    (comment) =>
                      comment.comment.scores.relevanceScore >= 3 &&
                      comment.comment.scores.relevanceScore <= 7
                  ).length /
                  commentData.filter((comment) =>
                    comment.comment.scores &&
                    comment.comment.scores.relevanceScore
                      ? comment.comment.scores.relevanceScore
                      : null
                  ).length) *
                100
              ).toFixed(1) + "%"
            : null,
        negativePercentage:
          commentData && commentData.length > 0
            ? (
                (commentData
                  .filter((comment) =>
                    comment.comment.scores &&
                    comment.comment.scores.relevanceScore
                      ? comment.comment.scores.relevanceScore
                      : null
                  )
                  .filter(
                    (comment) => comment.comment.scores.relevanceScore < 3
                  ).length /
                  commentData.filter((comment) =>
                    comment.comment.scores &&
                    comment.comment.scores.relevanceScore
                      ? comment.comment.scores.relevanceScore
                      : null
                  ).length) *
                100
              ).toFixed(1) + "%"
            : null,
      },
      sentimentData: {
        sentimentScore:
        (searched || selected) ? 
        commentData && commentData.length > 0
        ? (commentData
          .filter((comment) =>
            comment.comment.scores &&
            comment.comment.scores.sentimentScore != null
          )
          .reduce((sum, comment) => sum + comment.comment.scores.sentimentScore, 0) /
          commentData.filter((comment) =>
            comment.comment.scores &&
            comment.comment.scores.sentimentScore != null
          ).length) 
        : null
        :
          selectedPostData
            .filter((post) => post.data.totalComments > 0)
            .map((post) =>
              post.reports &&
              post.reports[0] &&
              post.reports[0].vibeCheck &&
              post.reports[0].vibeCheck.sentimentData
                ? parseFloat(
                    post.reports[0].vibeCheck.sentimentData.sentimentScore
                  )
                : 0
            )
            .reduce((a, b) => a + b, 0) /
          selectedPostData.filter((post) => post.data.totalComments > 0).length,
        positivePercentage:
          commentData && commentData.length > 0
            ? (
                (commentData
                  .filter((comment) =>
                    comment.comment.scores &&
                    comment.comment.scores.sentimentScore
                      ? comment.comment.scores.sentimentScore
                      : null
                  )
                  .filter(
                    (comment) => comment.comment.scores.sentimentScore > 7
                  ).length /
                  commentData.filter((comment) =>
                    comment.comment.scores &&
                    comment.comment.scores.sentimentScore
                      ? comment.comment.scores.sentimentScore
                      : null
                  ).length) *
                100
              ).toFixed(1) + "%"
            : null,
        neutralPercentage:
          commentData && commentData.length > 0
            ? (
                (commentData
                  .filter((comment) =>
                    comment.comment.scores &&
                    comment.comment.scores.sentimentScore
                      ? comment.comment.scores.sentimentScore
                      : null
                  )
                  .filter(
                    (comment) =>
                      comment.comment.scores.sentimentScore >= 3 &&
                      comment.comment.scores.sentimentScore <= 7
                  ).length /
                  commentData.filter((comment) =>
                    comment.comment.scores &&
                    comment.comment.scores.sentimentScore
                      ? comment.comment.scores.sentimentScore
                      : null
                  ).length) *
                100
              ).toFixed(1) + "%"
            : null,
        negativePercentage:
          commentData && commentData.length > 0
            ? (
                (commentData
                  .filter((comment) =>
                    comment.comment.scores &&
                    comment.comment.scores.sentimentScore
                      ? comment.comment.scores.sentimentScore
                      : null
                  )
                  .filter(
                    (comment) => comment.comment.scores.sentimentScore < 3
                  ).length /
                  commentData.filter((comment) =>
                    comment.comment.scores &&
                    comment.comment.scores.sentimentScore
                      ? comment.comment.scores.sentimentScore
                      : null
                  ).length) *
                100
              ).toFixed(1) + "%"
            : null,
      },
    };
    setVibeCheck(vibeCheck);
    // alert("Vibe Check Refreshed");
  }, [selectedPostData, fullCommentData, searchedComments, selectedComments, setVibeCheck, selected, searched]);

  const vibeScore =
    vibeCheck && vibeCheck.vibeScore
      ? parseFloat(vibeCheck.vibeScore).toFixed(1)
      : 0;
  const sentimentScore =
    vibeCheck && vibeCheck.sentimentData.sentimentScore
      ? parseFloat(vibeCheck.sentimentData.sentimentScore).toFixed(1)
      : 0;
  const relevanceScore =
    vibeCheck && vibeCheck.relevanceData.relevanceScore
      ? parseFloat(vibeCheck.relevanceData.relevanceScore).toFixed(1)
      : 0;
  const consensusScore =
    vibeCheck && vibeCheck.consensusData.consensusScore
      ? parseFloat(vibeCheck.consensusData.consensusScore).toFixed(1)
      : 0;

  const vibeColor = (color) => {
    return color >= 7
      ? "#089616"
      : color >= 5
      ? "#74d52e"
      : color >= 3
      ? "#ffb93d"
      : "#fb3e3e";
  };

  return (
    quickView ?
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1, width: "100%" }}>


    <Box sx={{ display: "flex", flexDirection: "row",width:"100%", justifyContent:"center", alignItems: "center", gap: 1 }}>

      <SpeedRounded sx={{ fontSize: 16, color: "#888" }} />
           {(selected || searched) && (
            <Chip
            size="small"
            label={selected ? `${selectedComments.length} Selected` : searched ? `Search` : ""}
              sx={{ backgroundColor: "#edecfe", color: "#666", fontSize: 12, fontWeight: 600 }}
            />
            )}
      <Typography variant="body2" align="center" sx={{ color: vibeColor(vibeScore), fontWeight: 700, fontSize:16 }}>
       {vibeScore}
      </Typography> 
            
      <LinearProgress
            variant="determinate"
            value={vibeScore * 10}
            sx={{
              borderRadius: 30,
              height: 10,
              backgroundColor: `${vibeColor(vibeScore)}50`, // Adjusted to RGBA with reduced opacity
              width: "60%",
              border: "transparent",
              transition: "width 0.5s ease-in-out", // Add this line
              "& .MuiLinearProgress-barColorPrimary": { background: vibeColor(vibeScore) },
            }}
          />

          

    </Box>

{/* {vibeCheck && vibeCheck.sentimentData ? (
      <Box sx={{ display: "flex", flexDirection: "row",width:"100%", justifyContent:"center", alignItems: "center", gap: 1 }}>
  <DataBar
   quickView
   vibeColor={vibeColor}
    isMobile={isMobile}
    exporting={exporting}
    label="Sentiment"
    score={sentimentScore}
    label1="Positive"
    label2="Neutral"
    label3="Negative"
    data1={
      vibeCheck && vibeCheck.sentimentData.positivePercentage
        ? vibeCheck.sentimentData.positivePercentage
        : null
    }
    data2={
      vibeCheck && vibeCheck.sentimentData.neutralPercentage
        ? vibeCheck.sentimentData.neutralPercentage
        : null
    }
    data3={
      vibeCheck && vibeCheck.sentimentData.negativePercentage
        ? vibeCheck.sentimentData.negativePercentage
        : null
    }
    color="#4828C5"
    description="Sentiment is a measure of how positive or negative the comments are. A higher sentiment score means that the comments are more positive. A lower sentiment score means that the comments are more negative."
  />
  </Box>
) : (
  <Box sx={{ mt: 2, mb: 2 }}>
    <Skeleton
      variant="rectangular"
      height={20}
      sx={{ borderRadius: 4 }}
    />
  </Box>
)}  */}

</Box>
    :
    <>
    <Dialog open={openImage} onClose={() => setOpenImage(false)} maxWidth="lg" fullWidth>
      <DialogTitle>
      <Box sx={{display:"flex", flexDirection:"row", justifyContent:"flex-end", alignItems:"center", height:25}}>

      {copied ? 
        <Typography variant="body2" color="#089616">Copied!</Typography>
      
        :
        <IconButton
        onClick={async () => {
          await navigator.clipboard.writeText(analyticsDefinitionText);
          setCopied(true); // Trigger the animation
          setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
        }}
        color="primary"
        sx={{ position: 'absolute', top: 10, right: 10 }} // Positioning the button
      >
      
        <ContentCopyOutlined />
      </IconButton>}
      </Box>
      </DialogTitle>
      <DialogContent>
        <img src="/siftsy_score_breakdown.png" alt="Siftsy Score Breakdown" style={{ width: '100%' }} />
      </DialogContent>
    </Dialog>
      <Box
        id="vibe-check-section"
        sx={{
          display: "flex",
          flexDirection: fullTable && !isMobile ? "column" : "row",
          gap: isMobile ? 1 : 2,
          zIndex: 1,
          position: "relative",
        }}
        ref={vibeCheckScreenshotRef}
      >

{!screenshot && hovered && !exporting && (
            <ButtonGroup
              size="small"
              sx={{
                background: "#f7f7f7",
                position: "absolute",
                top: 10,
                right: 10,
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                padding: "5px 10px",
                gap: ".5rem",
                borderRadius: 30,
                zIndex: 1000,
              }}
              id="metrics-actions"
            >
              <Tooltip title="Copy Vibe Check" placement="top">
                <IconButton
                  color="primary"
                  size="small"
                  onClick={handleCopyMetrics}
                >
                  {copied ? (
                    <CheckCircleOutlineOutlined
                      color="success"
                      sx={{ width: 20, height: 20 }}
                    />
                  ) : (
                    <ContentCopyOutlined sx={{ width: 20, height: 20 }} />
                  )}
                </IconButton>
              </Tooltip>

              <Tooltip title="Download As Image" placement="top">
                <IconButton
                  color="primary"
                  size="small"
                  onClick={handleExportVibeCheck}
                >
                  {exporting ? (
                    <CircularProgress size={20} />
                  ) : (
                    <Download sx={{ width: 20, height: 20 }} />
                  )}
                </IconButton>
              </Tooltip>
            </ButtonGroup>
          )}

        <Box
          sx={{
            backgroundColor: "#fff",
            border: "1px solid #DDD",
            borderRadius: 3,
            width: fullTable ? null : "45%",
            p: isMobile ? .5 : 2,
            // height:140
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 1,
            }}
          >
            {!isMobile && <SpeedRounded sx={{ fontSize: 20, color: "#888" }} />}

            <Typography
              variant="body2"
              align="left"
              sx={{ color: "#888", fontWeight: 700 }}
            >
              Vibe Check
            </Typography>
            {(selected || searched) && (
            <Chip
            size="small"
            label={selected ? `${selectedComments.length} Selected` : searched ? `Search` : ""}
              sx={{ backgroundColor: "#edecfe", color: "#666", fontSize: 12, fontWeight: 600 }}
            />
            )}
            <Box sx={{ flexGrow: 1 }} />

            {!exporting && (
              <IconButton
                ref={explainerButtonRef}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setOpenExplainer(!openExplainer);
                }}
              >
                <InfoOutlined sx={{ fontSize: 20, color: "#888" }} />
              </IconButton>
            )}
          </Box>

          {vibeCheck ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                transform: isMobile ? "translate(0%, 0%) scale(.9)" : "translate(0%, 0%) scale(1.3)",
                height: "100%",
                mt: isMobile ? -2 : 1,
              }}
            >
              <Scorer
                score={parseFloat(vibeCheck.vibeScore)}
                color={vibeColor(parseFloat(vibeCheck.vibeScore))}
              >
                <Avatar
                  sx={{
                    width: 120,
                    height: 40,
                    color: vibeColor(parseFloat(vibeCheck.vibeScore)),
                    background: "transparent",
                    fontSize: 28, //40
                    fontWeight: 700,
                  }}
                >
                  {vibeScore}
                </Avatar>
                <Typography
                  variant="body2"
                  fontSize={10}
                  fontWeight={700}
                  color="#888"
                >
                  {vibeScore >= 7.5
                    ? "Immaculate "
                    : vibeScore >= 5
                    ? "Good"
                    : vibeScore >= 3.5
                    ? "Subpar "
                    : "Off"}
                </Typography>
              </Scorer>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
            >
              <Skeleton variant="circular" width={120} height={120} />
            </Box>
          )}

          {vibeCheck && expanded && (
            <Grid container spacing={2}>
              {vibeCheck.sentimentData && (
                <Grid item xs={4}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography fontSize={10} fontWeight={600} color="#666">
                      Sentiment
                    </Typography>
                    <Typography fontSize={20} fontWeight={600} color="#555">
                      {
                        maxPercentage([
                          vibeCheck.sentimentData.positivePercentage,
                          vibeCheck.sentimentData.neutralPercentage,
                          vibeCheck.sentimentData.negativePercentage,
                        ]).value
                      }
                    </Typography>
                    <Chip
                      size="small"
                      sx={{
                        borderRadius: 2,
                        fontSize: 10,
                        opacity: 0.7,
                        width: 70,
                        height: 22,
                        userSelect: "none",
                        "& .MuiChip-label": {
                          width: "100%", // Make the label take full width of the chip
                          padding: 0, // Remove default padding
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          opacity: 1,
                        },
                        backgroundColor:
                          maxPercentage([
                            vibeCheck.sentimentData.positivePercentage,
                            vibeCheck.sentimentData.neutralPercentage,
                            vibeCheck.sentimentData.negativePercentage,
                          ]).index === 0
                            ? "#089616"
                            : maxPercentage([
                                vibeCheck.sentimentData.positivePercentage,
                                vibeCheck.sentimentData.neutralPercentage,
                                vibeCheck.sentimentData.negativePercentage,
                              ]).index === 1
                            ? "#74d52e"
                            : "#fb3e3e",
                        color: "#fff",
                      }}
                      label={
                        maxPercentage([
                          vibeCheck.sentimentData.positivePercentage,
                          vibeCheck.sentimentData.neutralPercentage,
                          vibeCheck.sentimentData.negativePercentage,
                        ]).index === 0
                          ? "Positive"
                          : maxPercentage([
                              vibeCheck.sentimentData.positivePercentage,
                              vibeCheck.sentimentData.neutralPercentage,
                              vibeCheck.sentimentData.negativePercentage,
                            ]).index === 1
                          ? "Neutral"
                          : "Negative"
                      }
                    />
                  </Box>
                </Grid>
              )}
              {vibeCheck.relevanceData && (
                <Grid item xs={4}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography fontSize={10} fontWeight={600} color="#666">
                      Relevance
                    </Typography>
                    <Typography fontSize={20} fontWeight={600} color="#555">
                      {
                        maxPercentage([
                          vibeCheck.relevanceData.positivePercentage,
                          vibeCheck.relevanceData.neutralPercentage,
                          vibeCheck.relevanceData.negativePercentage,
                        ]).value
                      }
                    </Typography>
                    <Chip
                      size="small"
                      sx={{
                        borderRadius: 2,
                        fontSize: 10,
                        opacity: 0.7,
                        width: 70,
                        height: 22,
                        backgroundColor:
                          maxPercentage([
                            vibeCheck.relevanceData.positivePercentage,
                            vibeCheck.relevanceData.neutralPercentage,
                            vibeCheck.relevanceData.negativePercentage,
                          ]).index === 0
                            ? "#089616"
                            : maxPercentage([
                                vibeCheck.relevanceData.positivePercentage,
                                vibeCheck.relevanceData.neutralPercentage,
                                vibeCheck.relevanceData.negativePercentage,
                              ]).index === 1
                            ? "#74d52e"
                            : "#fb3e3e",
                        color: "#fff",
                      }}
                      label={
                        maxPercentage([
                          vibeCheck.relevanceData.positivePercentage,
                          vibeCheck.relevanceData.neutralPercentage,
                          vibeCheck.relevanceData.negativePercentage,
                        ]).index === 0
                          ? "On-Topic"
                          : maxPercentage([
                              vibeCheck.relevanceData.positivePercentage,
                              vibeCheck.relevanceData.neutralPercentage,
                              vibeCheck.relevanceData.negativePercentage,
                            ]).index === 1
                          ? "General"
                          : "Off-Topic"
                      }
                    />
                  </Box>
                </Grid>
              )}
              {vibeCheck.consensusData &&
                vibeCheck.consensusData.consensusScore && (
                  <Grid item xs={4}>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography fontSize={10} fontWeight={600} color="#666">
                        Consensus
                      </Typography>
                      <Typography fontSize={20} fontWeight={600} color="#555">
                        {
                          maxPercentage([
                            vibeCheck.consensusData.positivePercentage,
                            vibeCheck.consensusData.neutralPercentage,
                            vibeCheck.consensusData.negativePercentage,
                          ]).value
                        }
                      </Typography>
                      <Chip
                        size="small"
                        sx={{
                          borderRadius: 2,
                          fontSize: 10,
                          opacity: 0.7,
                          width: 70,
                          height: 22,
                          backgroundColor:
                            maxPercentage([
                              vibeCheck.consensusData.positivePercentage,
                              vibeCheck.consensusData.neutralPercentage,
                              vibeCheck.consensusData.negativePercentage,
                            ]).index === 0
                              ? "#089616"
                              : maxPercentage([
                                  vibeCheck.consensusData.positivePercentage,
                                  vibeCheck.consensusData.neutralPercentage,
                                  vibeCheck.consensusData.negativePercentage,
                                ]).index === 1
                              ? "#74d52e"
                              : "#fb3e3e",
                          color: "#fff",
                        }}
                        label={
                          maxPercentage([
                            vibeCheck.consensusData.positivePercentage,
                            vibeCheck.consensusData.neutralPercentage,
                            vibeCheck.consensusData.negativePercentage,
                          ]).index === 0
                            ? "Agreement"
                            : maxPercentage([
                                vibeCheck.consensusData.positivePercentage,
                                vibeCheck.consensusData.neutralPercentage,
                                vibeCheck.consensusData.negativePercentage,
                              ]).index === 1
                            ? "Mixed"
                            : "Argument"
                        }
                      />
                    </Box>
                  </Grid>
                )}
            </Grid>
          )}

          <ClickAwayListener onClickAway={() => setOpenExplainer(false)}>
            <Popper
              open={openExplainer}
              anchorEl={explainerButtonRef.current}
              placement="top"
              modifiers={{
                offset: {
                  enabled: true,
                  offset: "0px, 10px",
                },
              }}
              style={{ zIndex: 2000 }}
            >
              <Paper
                sx={{
                  background: "#f7f7f7",
                  borderRadius: 3,
                  width: 300,

                  p: 2,
                }}
              >
                <Typography
                  variant="body2"
                  align="left"
                  sx={{ p: 1, background: "#f7f7f7", borderRadius: 3 }}
                >
                  <InfoOutlined sx={{ fontSize: 14, color: "#888" }} />
                  &nbsp; The <b>vibe score</b> is Siftsy's proprietary metric
                  that measures overall audience reception in comment sections
                  on a scale of 1-10. The score is calculated by averaging the
                  sentiment, relevance, and consensus of the comments.
                  <br />
                  <br />
                  Scores <b>closer to 10</b> imply a great atmosphere and a
                  receptive audience, while scores closer to 1 imply hostility
                  and "hatewatching".
                  <br />
                  <br />
                  Content with comment sections scoring <b>above a 5</b> are
                  generally great places for sponsors to invest their marketing
                  budgets, while lower ranking content often means controversy
                  and is not recommended for brand safety, positive brand
                  awareness, or to foster purchase consideration & intent.
                </Typography>
              </Paper>
            </Popper>
          </ClickAwayListener>
        </Box>

        <Box
          sx={{
            width: fullTable && !isMobile ? null : "80%",
            position: "relative",
          }}
        >


          <Box
            sx={{
              backgroundColor: "#fff",
              border: "1px solid #DDD",
              borderRadius: 3,
              p: isMobile ? 1 : 2,
              display: "flex",
              flexDirection: "column",
              gap: 2,
              // height:140
            }}
          >


            <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 1,
            }}
          >
              {!isMobile && (
                <BarChartOutlined
                  sx={{ fontSize: 20, color: "#888" }}
                />
              )}

            <Typography
              variant="body2"
              align="left"
              sx={{ color: "#888", fontWeight: 700 }}
            >
              Analytics
            </Typography>

            {(selected || searched) && (
            <Chip
            size="small"
            label={selected ? `${selectedComments.length} Selected` : searched ? `Search` : ""}
            sx={{ backgroundColor: "#edecfe", color: "#666", fontSize: 12, fontWeight: 600 }}
            />
            )}

          <IconButton
              onClick={() => setOpenImage(!openImage)} // Toggle image visibility
            >
              <InfoOutlined sx={{ fontSize: 20, color: "#888" }} />
            </IconButton>
            <Box sx={{ flexGrow: 1 }} />
             <IconButton size="small" color="primary">
                  <MoreHorizOutlined sx={{ fontSize: 20, color: "#fff" }} />
                </IconButton>
            {/* {!exporting && (
              <IconButton
                ref={explainerButtonRef}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setOpenExplainer(!openExplainer);
                }}
              >
                <InfoOutlined sx={{ fontSize: 20, color: "#888" }} />
              </IconButton>
            )} */}
          </Box>

            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              <Grid container spacing={1}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    // ml: 3,
                    // mr: 3,
                  
                    pr:1,
                    pl:1,
                    flexGrow: 1,
                    gap: 1,
                  }}
                >
                  {vibeCheck && vibeCheck.sentimentData ? (
                    <DataBar
                      isMobile={isMobile}
                      exporting={exporting}
                      label="Sentiment"
                      score={sentimentScore}
                      label1="Positive"
                      label2="Neutral"
                      label3="Negative"
                      data1={
                        vibeCheck && vibeCheck.sentimentData.positivePercentage
                          ? vibeCheck.sentimentData.positivePercentage
                          : null
                      }
                      data2={
                        vibeCheck && vibeCheck.sentimentData.neutralPercentage
                          ? vibeCheck.sentimentData.neutralPercentage
                          : null
                      }
                      data3={
                        vibeCheck && vibeCheck.sentimentData.negativePercentage
                          ? vibeCheck.sentimentData.negativePercentage
                          : null
                      }
                      color="#4828C5"
                      description="Sentiment is a measure of how positive or negative the comments are. A higher sentiment score means that the comments are more positive. A lower sentiment score means that the comments are more negative."
                    />
                  ) : (
                    <Box sx={{ mt: 2, mb: 2 }}>
                      <Skeleton
                        variant="rectangular"
                        height={20}
                        sx={{ borderRadius: 4 }}
                      />
                    </Box>
                  )}
                  {vibeCheck && vibeCheck.relevanceData ? (
                    <DataBar
                      isMobile={isMobile}
                      exporting={exporting}
                      label="Relevance"
                      score={relevanceScore}
                      color="#4828C5"
                      data1={
                        vibeCheck && vibeCheck.relevanceData.positivePercentage
                          ? vibeCheck.relevanceData.positivePercentage
                          : null
                      }
                      data2={
                        vibeCheck && vibeCheck.relevanceData.neutralPercentage
                          ? vibeCheck.relevanceData.neutralPercentage
                          : null
                      }
                      data3={
                        vibeCheck && vibeCheck.relevanceData.negativePercentage
                          ? vibeCheck.relevanceData.negativePercentage
                          : null
                      }
                      label1="On-Topic"
                      label2="General"
                      label3="Off-Topic"
                      description="Relevance is a measure of how relevant the comments are to the video. A higher relevance score means that the comments are more relevant to the video."
                    />
                  ) : (
                    <Box sx={{ mt: 2, mb: 2 }}>
                      <Skeleton
                        variant="rectangular"
                        height={20}
                        sx={{ borderRadius: 4 }}
                      />
                    </Box>
                  )}
                  {vibeCheck &&
                  vibeCheck.consensusData &&
                  vibeCheck.consensusData.consensusScore ? (
                    <DataBar
                      isMobile={isMobile}
                      exporting={exporting}
                      label="Consensus"
                      score={consensusScore}
                      color="#4828C5"
                      label1="Agreement"
                      label2="Mixed"
                      label3="Argument"
                      data1={
                        vibeCheck && vibeCheck.consensusData.positivePercentage
                          ? vibeCheck.consensusData.positivePercentage
                          : null
                      }
                      data2={
                        vibeCheck && vibeCheck.consensusData.neutralPercentage
                          ? vibeCheck.consensusData.neutralPercentage
                          : null
                      }
                      data3={
                        vibeCheck && vibeCheck.consensusData.negativePercentage
                          ? vibeCheck.consensusData.negativePercentage
                          : null
                      }
                      description={
                        vibeCheck &&
                        vibeCheck.consensusData &&
                        vibeCheck.consensusData.info
                          ? vibeCheck.consensusData.info
                          : `Consensus is a measure of how much the comments agree with each other. A higher consensus score means that the comments are more similar to each other. A lower consensus score means that the comments are more diverse.`
                      }
                    />
                  ) : (
                    <Box sx={{ mt: 2, mb: 2 }}>
                      <Skeleton
                        variant="rectangular"
                        height={20}
                        sx={{ borderRadius: 4 }}
                      />
                    </Box>
                  )}
                </Box>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
});

export default VibeCheckSection;

function DataBar(props) {
  const {
    label,
    score,
    color,
    description,
    label1,
    label2,
    label3,
    data1,
    data2,
    data3,
    exporting,
    isMobile,
    quickView,
    vibeColor
  } = props;

  const [open, setOpen] = React.useState(false);

  const numMetrics = [data1, data2, data3].filter((data) => data).length;

  let icons = {
    SENTIMENT: [
      <SentimentVerySatisfiedOutlined sx={{ height: 16, width: 16 }} />,
      <Mood sx={{ fontSize: 14 }} />,
      <SentimentVeryDissatisfiedOutlined sx={{ height: 16, width: 16 }} />,
    ],
    RELEVANCE: [
      <TaskAltOutlined sx={{ height: 16, width: 16 }} />,
      <Stars sx={{ fontSize: 14 }} />,
      <HighlightOffOutlined sx={{ height: 16, width: 16 }} />,
    ],
    CONSENSUS: [
      <HandshakeOutlined sx={{ height: 16, width: 16 }} />,
      <HowToVoteOutlined sx={{ fontSize: 14 }} />,
      <GavelOutlined sx={{ height: 16, width: 16 }} />,
    ],
  };

  const explanation = (label, score) => {
    if (label == "SENTIMENT") {
      if (score >= 7) {
        return "Overwhelming positivity.";
      } else if (score >= 5) {
        return "Mostly positive.";
      } else if (score >= 3) {
        return "Mix of positive and negative.";
      } else {
        return "Mostly negative.";
      }
    } else if (label == "RELEVANCE") {
      if (score >= 7) {
        return "High relevance.";
      } else if (score >= 5) {
        return "Mostly relevant.";
      } else if (score >= 3) {
        return "Somewhat off-topic.";
      } else {
        return "Mostly off-topic.";
      }
    } else if (label == "CONSENSUS") {
      if (score >= 7) {
        return "Strong agreement.";
      } else if (score >= 5) {
        return "Mostly in agreement.";
      } else if (score >= 3) {
        return "Mixed opinions.";
      } else {
        return "Mostly disagreeing.";
      }
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        cursor: "pointer",
        borderRadius: 2,
        width: quickView ? "70%" : "100%",
      }}
      onClick={!quickView && data1 ? () => setOpen(!open) : null}
    >
      {!quickView && !isMobile && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 0.5,
            color: "#888",
          }}
        >
          {icons[label.toUpperCase()][1]}
          <Typography fontSize={10} fontWeight={600} color="#888">
            {label}
          </Typography>
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 1,
        }}
      >
        {quickView && ( 
     <Box
     sx={{
       display: "flex",
       flexDirection: "row",
       alignItems: "center",
       gap: 1,
       color: "#888",
     }}
   >
               {icons[label.toUpperCase()][1]}
               <Typography variant="body2" fontWeight={700} fontSize={14} color={vibeColor(score)}>
          {score}
        </Typography>
          </Box>
        )}

        <Box
          sx={{
            width: "90%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 0.3,
          }}
        >
          <LinearProgress
            variant="determinate"
            value={100}
            sx={{
              borderRadius: 30,
              height: 10,
              width: data1,
              background: "#EEE",
              opacity: 0.6,
              border: "transparent",
              transition: "width 0.5s ease-in-out", // Add this line
              "& .MuiLinearProgress-barColorPrimary": { background: "#089616" },
            }}
          />
          <LinearProgress
            variant="determinate"
            value={100}
            sx={{
              borderRadius: 30,
              height: 10,
              width: data2,
              background: "#EEE",
              opacity: 0.6,
              border: "transparent",
              transition: "width 0.5s ease-in-out", // Add this line
              "& .MuiLinearProgress-barColorPrimary": { background: "#ffb93d" },
            }}
          />
          <LinearProgress
            variant="determinate"
            value={100}
            sx={{
              borderRadius: 30,
              height: 10,
              width: data3,
              background: "#EEE",
              opacity: 0.6,
              border: "transparent",
              transition: "width 0.5s ease-in-out", // Add this line
              "& .MuiLinearProgress-barColorPrimary": { background: "#fb3e3e" },
            }}
          />
        </Box>

        {!quickView && ( 
        <Typography variant="body2" fontWeight={700} fontSize={14}>
          {score}
        </Typography>

        )}

        {!quickView && data1 &&
          !exporting &&
          !isMobile &&
          (open ? (
            <ExpandLessOutlined // Conditionally render the icon based on hover state
              sx={{
                cursor: "pointer",
                width: 20,
                height: 16,
                color: "#4727c3",
                marginLeft: 1, // Add some space between the text and the icon
              }}
            />
          ) : (
            <ExpandMoreOutlined // Conditionally render the icon based on hover state
              sx={{
                cursor: "pointer",
                width: 20,
                height: 16,
                color: "#4727c3",
                marginLeft: 1, // Add some space between the text and the icon
              }}
            />
          ))}
      </Box>
      <Box
        sx={{
          display: open ? "flex" : "none",
          p: isMobile ? 0.5 : 1,
          pl: isMobile ? 0.5 : 2,
          pr: isMobile ? 0.5 : 2,
          borderRadius: 2,
          backgroundColor: "#f7f7f7",
        }}
      >
        <Grow in={open}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 0.5,
              color: "#888",
              width: "100%",
            }}
          >
            {data1 && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  flexGrow: 1,
                  borderRadius: 2,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    width: "100%",
                    gap: 0.5,
                  }}
                >
                  {/* {icons[label][0]} */}

                  <Typography
                    variant="body1"
                    fontSize={isMobile ? 8 : 10}
                    fontWeight={700}
                    align="left"
                    textTransform={"uppercase"}
                  >
                    {label1}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: 1,
                  }}
                >
                  <Typography
                    variant="body1"
                    fontWeight={600}
                    fontSize={isMobile ? 14 : 18}
                    color="#333"
                  >
                    {data1}
                  </Typography>
                </Box>
              </Box>
            )}
            {data2 && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  flexGrow: 1,
                  // border: "1px solid #ddd",
                  // p: 1,

                  borderRadius: 2,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    width: "100%",
                    gap: 0.5,
                  }}
                >
                  {/* {icons[label][1]} */}

                  <Typography
                    variant="body1"
                    fontSize={isMobile ? 8 : 10}
                    fontWeight={700}
                    align="left"
                    textTransform={"uppercase"}
                  >
                    {label2}
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      gap: 1,
                    }}
                  >
                    <Typography
                      variant="body1"
                      fontWeight={600}
                      fontSize={isMobile ? 14 : 18}
                      color="#333"
                      align="left"
                    >
                      {data2}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}
            {data3 && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  flexGrow: 1,
                  // border: "1px solid #ddd",
                  // p: 1,

                  borderRadius: 2,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    width: "100%",
                    gap: 0.5,
                  }}
                >
                  {/* {icons[label][2]} */}

                  <Typography
                    variant="body1"
                    fontSize={isMobile ? 8 : 10}
                    fontWeight={700}
                    align="left"
                    textTransform={"uppercase"}
                  >
                    {label3}
                  </Typography>
                  
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      width: "100%",
                      gap: 1,
                    }}
                  >
                    <Typography
                      variant="body1"
                      fontWeight={600}
                      fontSize={isMobile ? 14 : 18}
                      color="#333"
                      align="left"
                    >
                      {data3}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Grow>
      </Box>
    </Box>
  );
}

// const Scorer = (props) => {
//   const { score, color } = props;

//   const svgStyle = {
//     height: 120,
//     width: 120,
//     position: "absolute",
//     top: 0,
//     left: 0,
//     fill: "transparent",
//   };

//   const circleStyle = {
//     fill: "transparent",
//   };

//   const dashArray = 2 * Math.PI * 50;
//   const dashOffset = dashArray - (dashArray * score) / 10;

//   const progressBorderStyle = {
//     stroke: color,
//     strokeWidth: 15,
//     strokeLinecap: "round",
//     strokeDasharray: dashArray,
//     strokeDashoffset: dashOffset,
//   };

//   const trackStyle = {
//     stroke: color,
//     opacity: 0.5,
//     strokeWidth: 15,
//     strokeLinecap: "round",
//     // Change number value to shift the progress bar
//   };

//   return (
//     <div
//       style={{
//         position: "absolute",
//         top: 140,
//         left: 0,
//         transform: "rotate(-90deg)",
//       }}
//     >
//       <svg style={svgStyle}>
//         {/* <circle id="border-track" cx="75" cy="75" r="65" style={circleStyle} /> */}
//         <circle id="track" cx="60" cy="60" r="50" style={trackStyle} />
//         {/* <circle id="progress" cx="75" cy="75" r="65" style={{ ...circleStyle, ...progressStyle }} /> */}
//         <circle
//           id="progress-border"
//           cx="60"
//           cy="60"
//           r="50"
//           style={{ ...circleStyle, ...progressBorderStyle }}
//         />
//       </svg>

//       <div
//         style={{
//           position: "absolute",
//           top: 0,
//           left: 0,
//           transform: "rotate(90deg)",
//         }}
//       >
//         {props.children}
//       </div>
//     </div>
//   );
// };
const Scorer = (props) => {
  const { score, color } = props;

  const svgStyle = {
    height: 120,
    width: 120,
    position: "absolute",
    top: 0,
    left: 0,
    fill: "transparent",
  };

  const circleStyle = {
    fill: "transparent",
  };

  const radius = 50;
  const circumference = Math.PI * radius;
  const dashOffset = circumference - (circumference * score) / 10;

  const progressBorderStyle = {
    stroke: color,
    strokeWidth: 8,
    opacity: 0.5,
    strokeLinecap: "round",
    strokeDasharray: `${circumference} ${circumference}`,
    strokeDashoffset: dashOffset,
    transition: "all 0.5s ease",
  };

  const trackStyle = {
    stroke: color,
    opacity: 0.5,
    strokeWidth: 8,
    strokeLinecap: "round",
  };

  return (
    <div style={{ position: "relative", width: 120, height: 120 }}>
      <svg style={svgStyle}>
        <g transform="rotate(-90, 60, 60)">
          <path
            id="track"
            d="M60 10 A50 50 0 1 1 60 110"
            style={trackStyle}
            fill="none"
          />
          <path
            id="progress-border"
            d="M60 10 A50 50 0 1 1 60 110"
            style={{ ...circleStyle, ...progressBorderStyle }}
            fill="none"
          />
        </g>
      </svg>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
        }}
      >
        {props.children}
      </div>
    </div>
  );
};
