import {
  Box,
  Grid,
  Typography,
  Card,
  createTheme,
  ButtonBase,
  Tabs,
  Tab,
  Avatar,
  Button,
  Paper,
  IconButton,
  CardMedia,
  Chip,
  Grow,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  Popper,
  ClickAwayListener,
  Skeleton,
  Icon,
  Tooltip,
  Divider,
  Popover,
  Fade,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { styled as muiStyled } from "@mui/material/styles";
import {
  Add,
  AnalyticsOutlined,
  ArrowBackIos,
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  AssessmentOutlined,
  BackHand,
  BackspaceOutlined,
  BarChartOutlined,
  CalculateOutlined,
  Check,
  Close,
  CommentOutlined,
  Download,
  ExpandMore,
  FastRewindOutlined,
  FavoriteBorder,
  FilterList,
  Fullscreen,
  FullscreenExitOutlined,
  Group,
  LineAxisOutlined,
  LocalActivityRounded,
  NumbersOutlined,
  OpenInNew,
  OpenInNewOutlined,
  Percent,
  PieChartOutlined,
  Redo,
  Refresh,
  Replay,
  Reply,
  SendOutlined,
  ShowChartOutlined,
  ShowChartRounded,
  StackedLineChartOutlined,
  TimelineOutlined,
  VisibilityOutlined,
  CopyAllOutlined,
  Work,
} from "@mui/icons-material";
import { siftsyTheme } from "./siftsytheme";
import { useUser, UserButton } from "@clerk/clerk-react";
import {
  formatData,
  formatNumber,
  formatPlatform,
  UTCtoLocal,
  formatFullDate,
  estimateViews
} from "./Helpers";
import GroupReport from "./GroupReport";
import { AgGridReact } from "ag-grid-react"; // AG Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import Functions from "./Functions";
import { initial, set } from "lodash";
import html2canvas from "html2canvas";
import Footer from "./Footer";
import ContentMetrics from "./ContentMetrics";
import SelectChips from "./SelectChips";
import ExportMetricsModal from "./ExportMetricsModal";
import VibeCheckSection from "./VibeCheckSection";
import { BarChart } from "recharts";
import "./TableStyles.css";
import ContentTagPicker from "./ContentTagPicker";
import GroupPicker from "./GroupPicker";
let ENV = process.env.REACT_APP_ENV;

export default function GroupView(props) {
  const { isLoaded, isSignedIn, user } = useUser();
  const {
    teamData,
    viewportHeight,
    viewportWidth,
    selectedPostData,
    handleSelectedPostData,
    resetPosts,
    refreshData,
    loading,
    backToDash,
    groupView,
    setGroupView,
    selectedGroups,
    forwardToPost,
    mobileTab,
    selectedPost,
    softRefresh,
    showVibeCheck,
    setShowVibeCheck,
  
  } = props;

  const metricsRef = React.useRef(null);



  const [empty, setEmpty] = React.useState(true);
  const [selectedComments, setSelectedComments] = React.useState([]);
  const [exportMetrics, setExportMetrics] = React.useState(false);
  const [fullTable, setFullTable] = React.useState(() => {
    const savedFullTable = localStorage.getItem('fullTable');
    return savedFullTable ? JSON.parse(savedFullTable) : false;
  });  const [exporting, setExporting] = React.useState(false);
  const [selectedPostRows, setSelectedPostRows] = React.useState([]);
  const [minimizeMetrics, setMinimizeMetrics] = React.useState(() => {
    const savedMinimizeMetrics = localStorage.getItem('minimizeMetrics');
    return savedMinimizeMetrics ? JSON.parse(savedMinimizeMetrics) : false;
  });  const updateGroupView = React.useCallback((view) => {
    setGroupView(view);
  }, []);

  const [hoveredThumbnail, setHoveredThumbnail] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const tableRef = React.useRef(null);
  const selectedRowsRef = React.useRef([]); // Ref to store the current selection
  const [openPopper, setOpenPopper] = React.useState(false);
  const [quickFilterText, setQuickFilterText] = React.useState("");

  const handleRowHover = (target, thumbnail) => {
    setHoveredThumbnail(thumbnail);
    //anchor at top left corner of the table
    //get position of the mouse

    // let element = document.querySelector(`[row-index="${event.rowIndex}"]`);
    setAnchorEl(target);
    console.log("target", target);
  };

  const handleClosePopper = () => {
    setHoveredThumbnail(null);
    setAnchorEl(null);
  };

  React.useEffect(() => {
    selectedPostData.length > 0 ? setEmpty(false) : setEmpty(true);
    // updateGroupView("comments");
  }, [selectedPostData]);

  const goToPost = React.useCallback(
    (postId) => {

      forwardToPost(postId);
      updateGroupView("comments");
      // handleSetFullTable(false);

    },
    [teamData]
  );

  const handleMinimizeMetrics = React.useCallback((state) => {
    setMinimizeMetrics(state);
    setFullTable(state);
    localStorage.setItem('minimizeMetrics', JSON.stringify(state));
    localStorage.setItem('fullTable', JSON.stringify(state));
  }, []);

  const handleSetFullTable = React.useCallback((state) => {
    setFullTable(state);
    setMinimizeMetrics(state);
    localStorage.setItem('fullTable', JSON.stringify(state));
    localStorage.setItem('minimizeMetrics', JSON.stringify(state));
  }, []);

const handleExportMetrics = React.useCallback((state) => {
    setExportMetrics(state);
  } , []);

  const handleSelectedComments = React.useCallback((comments) => {
    setSelectedComments(comments);
  }, []);

  const handleSelectedPostRows = React.useCallback((rows) => {
    setSelectedPostRows(rows);
  }, []);

  const onSelectionChanged = React.useCallback(() => {
    const selectedNodes = tableRef.current.api.getSelectedNodes();
    const selectedData = selectedNodes.map(node => node.data);
    // console.log("selectedData", selectedData);
    selectedRowsRef.current = selectedData;
    
  }, []);

  const copyTableDataToClipBoard = () => {
    if (!tableRef.current || !tableRef.current.api) {
      console.error('Table reference is not set or API is not available.');
      return;
    }

    const allNodes = tableRef.current.api.getRenderedNodes(); // Get all nodes
    const allData = allNodes.map(node => ({
        // id: node.data.id,
        account: node.data.account,
        username: node.data.username,
        campaigns: node.data.groupIds,
        tags: node.data.tags,
        vibeScore: node.data.vibeScore,
        platform: node.data.platform,
        views: node.data.views,
        comments: node.data.comments,
        likes: node.data.likes,
        shares: node.data.shares,
        saves: node.data.saves
        // Add any other properties you need
    }));
    
    const csvData = allData.map(row => Object.values(row).join(',')).join('\n');
    
    navigator.clipboard.writeText(csvData).then(() => {
      console.log('Data copied to clipboard');
    }).catch(err => {
      console.error('Error copying data: ', err);
    });
  };
  
  const isMobile = viewportWidth < 900;

  return !empty ? (
    <Grid container spacing={2} sx={{ p: isMobile ? .5 : 2, pt: 0 }}>
       <Grid
            item
            xs={12}
            md={minimizeMetrics? 12 : 5}
            lg={minimizeMetrics? 12 : 4}
            sx={{ pb: minimizeMetrics ? 0 :5, display: !isMobile ? "inline-block" : "none" }}
          > 
     
     
            <>
          {!minimizeMetrics && (
            <Box
              sx={{
                display: "flex",
                height: isMobile ? "40px" : "50px",
                flexDirection: "row",
              }}
            >
              <Tabs>
                <Tab
                  label={
                    <Typography
                      variant="body1"
                      sx={{
                        color: "#4728c4",
                        fontWeight: 700,
                        textTransform: "none",
                        fontSize: isMobile ? 14 : 16,
                      }}
                    >
                      {" "}
                      Metrics
                    </Typography>
                  }
                  value={true}
                />
              </Tabs>
            </Box>

          )}
   <Grow in={true}>
            <Box
              sx={
                !minimizeMetrics && {
                overflowY: "scroll",
                height:
                  viewportWidth < 900
                    ? "calc(100vh - 195px)"
                    : "calc(100vh - 224px)",
                "&::-webkit-scrollbar": {
                  width: "0px",
                },
                "&::-webkit-scrollbar-track": {
                  background: "transparent",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "transparent",
                },
              }}
            >
              <ContentMetrics
              setMinimizeMetrics={handleMinimizeMetrics}
              minimizeMetrics={minimizeMetrics}
                handleExportMetrics={handleExportMetrics}
                selectedPostData={selectedPostData}
                teamData={teamData}
                refreshData={refreshData}
                isMobile={isMobile}
                softRefresh={softRefresh}
                small={viewportHeight < 1000}
              />

              {!minimizeMetrics &&
              <>
              <Footer />
              <br />
              <br /> <br />
              <br /> <br />
              <br />
              <ExportMetricsModal
                open={exportMetrics}
                onClose={() => setExportMetrics(false)}
                selectedPostData={selectedPostData}
                teamData={teamData}
                selectedGroups={selectedGroups}
              />
              </>
              }
            </Box>
            </Grow>
        </>

    
  </Grid>
      <Grid item xs={12} md={fullTable || minimizeMetrics ? 12 : 7} lg={fullTable || minimizeMetrics ? 12 : 8}>
        <Box sx={{ height: "fit-content" }}>
          <Box
            sx={{
              display: "flex",
              height: isMobile ? "40px" : "50px",
              flexDirection: "row",
            }}
          >
            {selectedPostData.length > 1 || isMobile ? (
              <Tabs
                value={groupView}
                onChange={(event, newValue) => updateGroupView(newValue)}
                sx={{ height: isMobile ? "40px" : "50px" }}
                //place indicator on top of the tab
                TabIndicatorProps={{
                  style: { top: 0 },
                }}
              >
                {isMobile && (
                  <Tab
                    value="metrics"
                    label={
                      <Typography
                        variant="body1"
                        sx={{
                          color: groupView == "metrics" ? "#4728c4" : "#888",
                          fontWeight: 700,
                          textTransform: "none",
                          fontSize: isMobile ? 14 : 16,
                          height: 45,
                        }}
                      >
                        {" "}
                        Metrics
                      </Typography>
                    }
                  />
                )}
                <Tab
                  label={
                    <Typography
                      variant="body1"
                      sx={{
                        color: groupView == "comments" ? "#4728c4" : "#888",
                        fontWeight: 700,
                        textTransform: "none",
                        fontSize: isMobile ? 14 : 16,
                        height: 45,
                      }}
                    >
                      {`Comments (${formatNumber(
                        selectedPostData
                          .map((post) => post.data.engagement ? post.data.engagement.comments : 0)
                          .reduce((a, b) => a + b, 0)
                      )})`}
                    </Typography>
                  }
                  value="comments"
                />
                {selectedPostData.length > 1 && (
                  <Tab
                    label={
                      <Typography
                        variant="body1"
                        sx={{
                          color: groupView == "posts" ? "#4728c4" : "#888",
                          fontWeight: 700,
                          textTransform: "none",
                          fontSize: isMobile ? 14 : 16,
                          height: 45,
                        }}
                      >
                        Posts{" "}
                        {selectedPostData.length > 0
                          ? `(${selectedPostData.length})`
                          : ""}
                      </Typography>
                    }
                    value="posts"
                  />
                )}
              </Tabs>
            ) : (
              <Box sx={{ display: "flex", height: isMobile ? "40px" : "50px" }}>
                <Typography
                  variant="body1"
                  sx={{
                    mt: 1,
                    ml: 2,
                    color: "#4728c4",
                    fontWeight: 700,
                    textTransform: "none",
                    fontSize: isMobile ? 14 : 16,
                  }}
                >
                  {`Comments (${formatNumber(
                    selectedPostData
                      .map((post) => post.data.engagement.comments)
                      .reduce((a, b) => a + b, 0)
                  )})`}
                </Typography>
              </Box>
            )}



            <Box sx={{ flexGrow: 1 }} />

            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 1,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress size={20} />
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 1,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {!isMobile && groupView === "posts" && (
                  <>
                  <TextField
                    size="small"
                    variant="outlined"
                    sx={{border: "1px solid #ccc", borderRadius: 2, background:"#fff"}}
                    placeholder="Search Posts"
                    value={quickFilterText}
                    onInput={(e) => setQuickFilterText(e.target.value)}
                  />
                  <Tooltip title="Export Post Data as CSV">
                    <IconButton
                      size="small"
                      onClick={() => {
                        if (
                          tableRef &&
                          tableRef.current &&
                          tableRef.current.api
                        ) {
                          tableRef.current.api.exportDataAsCsv();
                        }
                      }}
                    >
                      <Download />
                    </IconButton>
                  </Tooltip>
                  {/* <Tooltip title="Copy Data">
                  <IconButton onClick={copyTableDataToClipBoard}>
                    <CopyAllOutlined />
                  </IconButton>
                </Tooltip> */}
                  </>
                )}
          
      
                <Tooltip title="Refresh Data">
                  <IconButton onClick={refreshData}>
                    <Refresh />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
            {viewportWidth > 900 ? (
              <Tooltip title={fullTable ? "Minimize View" : "Maximize View"}>
                <IconButton onClick={() => handleSetFullTable(!fullTable)}>
                  {fullTable ? <FullscreenExitOutlined /> : <Fullscreen />}
                </IconButton>
              </Tooltip>
            ) : null}
          </Box>

          {/* {groupView == "metrics" && viewportWidth < 900 && ( */}
            <Box
              sx={{
                display: groupView == "metrics" && viewportWidth < 900 ? "block" : "none",
                overflowY: "scroll",
                height:
                  viewportWidth < 900
                    ? "calc(100vh - 195px)"
                    : "calc(100vh - 224px)",
              }}
            >
              <ContentMetrics
                handleExportMetrics={handleExportMetrics}
                selectedPostData={selectedPostData}
                teamData={teamData}
                refreshData={refreshData}
                isMobile={isMobile}
              />
              <Footer />
              <br />
              <br /> <br />
              <br /> <br />
              <br />
              <ExportMetricsModal
                open={exportMetrics}
                onClose={() => setExportMetrics(false)}
                selectedPostData={selectedPostData}
                teamData={teamData}
                selectedGroups={selectedGroups}
              />
            </Box>
            <SelectChipsPopper
                selectedRows={selectedRowsRef.current}
                teamData={teamData}
                refreshData={refreshData}
                softRefresh={softRefresh}
                viewportWidth={viewportWidth}
                openPopper={openPopper}
              />
            <Box sx={{display: groupView === "comments" ? "block" : "none"}}>
            <GroupReport
              teamData={teamData}
              viewportHeight={viewportHeight}
              viewportWidth={viewportWidth}
              selectedPostData={selectedPostData}
              selectedComments={selectedComments}
              handleSelectedComments={handleSelectedComments}
              selectedGroups={selectedGroups}
              goToPost={goToPost}
              fullTable={fullTable}
              showVibeCheck={showVibeCheck}
              setShowVibeCheck={setShowVibeCheck}
            /> 
            </Box>
{/* 
          {groupView === "comments" ? (

            
          ) : (
            <>
           

              <Paper elevation={0} sx={{ width: "100%", overflowX: "auto" }}>
                {loading && (
                  <Box
                    sx={{
                      overflow: "hidden",
                      width: "100%",
                      height: viewportHeight - 300,
                      borderRadius: 2,
                      border: "1px solid #ccc",
                      scroll: "none",
                    }}
                  >
                    <Box sx={{ height: 55, background: "#eee" }} />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        height: viewportHeight / 2,
                        gap: 2,
                        p: 2,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <CircularProgress size={100} />
                    </Box>
                  </Box>
                  
                )}
              </Paper>
            </>
          )} */}
          {groupView == "posts" && (
      
                    <PostTable
                    quickFilterText={quickFilterText}
                    selectedPostRows={selectedPostRows}
                    setSelectedPostRows={handleSelectedPostRows}
                      selectedPostData={selectedPostData}
                      teamData={teamData}
                      refreshData={refreshData}
                      softRefresh={softRefresh}
                      tableRef={tableRef}
                      onSelectionChanged={onSelectionChanged}
                      isMobile={isMobile}
                      goToPost={goToPost}
                      viewportHeight={viewportHeight}
                      height={fullTable ? "calc(100vh - 325px)" : "calc(100vh - 230px)"}
                      loading={loading}
                      copyTableDataToClipBoard={copyTableDataToClipBoard}
                    />
                  )}
              
        </Box>
      </Grid>
    </Grid>
  ) : (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap:4,
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        m: 4,
      }}
    >
      <Typography variant="h6" align="center" sx={{ color: "#888" }}>
        No posts here... yet!
        <Typography variant="body1" align="center" sx={{ color: "#888" }}>
        Add posts or adjust filter settings above to view posts.
      </Typography>
      </Typography>
  
      <Button
        
        variant="contained"
        sx={{
          borderRadius: 30,
          textTransform: "none",
          fontWeight: 600,
          padding: "10px 20px",
          fontSize: 20,
        }}
        startIcon={<Add />}
        onClick={() => props.openAddNew("post", true)}
      >
        Add Posts
      </Button>
      
      <Box sx={{ display: "flex", overflow:"hidden", borderRadius:8, boxShadow: 10,
      background:"#000",
        height: Math.min(viewportWidth - 50, 500),
        width: Math.min(viewportWidth - 50, 500),
       }}>
         <iframe 
        src="https://drive.google.com/file/d/16WwSCk6-lkUh8X2_hTvdgB8hM1nCn03q/preview" 
        width={Math.min(viewportWidth - 50, 500)}
        height={Math.min(viewportWidth - 50, 500)}
        allow="autoplay; encrypted-media" 
        allowFullScreen
      ></iframe>
       </Box>
                   

    </Box>
  );
}

function dateComparator(date1, date2) {
  var date1Timestamp = new Date(date1).getTime();
  var date2Timestamp = new Date(date2).getTime();

  if (date1Timestamp < date2Timestamp) {
    return -1;
  } else if (date1Timestamp > date2Timestamp) {
    return 1;
  } else {
    return 0;
  }
}

const MiniScorer = ({ score }) => {
  let color =
    score >= 7
      ? "#089616"
      : score >= 5
      ? "#74d52e"
      : score >= 3
      ? "#ffb93d"
      : "#fb3e3e";

  const svgStyle = {
    height: "140px",
    width: "140px",
    position: "absolute",
    transform: "rotate(-85deg)",
    fill: "transparent",
    top: 0,
    left: 0,
  };

  const circleStyle = {
    fill: "transparent",
  };

  const progressBorderStyle = {
    stroke: color,
    strokeWidth: "20px",
    strokeLinecap: "round",
    strokeDasharray: "440",
    // Change number value to shift the progress bar // 130 = 100, 440 = 0
    strokeDashoffset: score == 10 ? 130 : 440 - (310 / 10) * score + 8,
  };

  const trackStyle = {
    stroke: color,
    opacity: 0.5,
    strokeWidth: "20px",
    strokeLinecap: "round",
    strokeDasharray: "440",
    strokeDashoffset: "130", // Change number value to shift the progress bar
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        position: "relative",
      }}
    >
      <Avatar
        sx={{
          width: "40px",
          height: "40px",
          color: color,
          background: "transparent",
          fontSize: "1rem",
          fontWeight: 900,
        }}
      >
        {score}
      </Avatar>

      {/* <svg style={svgStyle}>
        <circle id="track" cx="80" cy="60" r="50" style={trackStyle} />
        <circle
          id="progress-border"
          cx="80"
          cy="60"
          r="50"
          style={{ ...circleStyle, ...progressBorderStyle }}
        />
      </svg> */}
    </Box>
  );
};







const PostTable = (props) => {

  const { selectedPostData, loading, height, setSelectedPostRows, selectedPostRows, teamData, refreshData, softRefresh, tableRef, onSelectionChanged, isMobile, goToPost, viewportHeight, quickFilterText } = props;

  const [openMultiplePopper, setOpenMultiplePopper] = React.useState(false);
  const [rowData, setRowData] = React.useState([]);
  
  const updateCampaigns = (newCampaign) => {
    const updatedRows = selectedPostRows.map(row => ({
      ...row,
      campaign: newCampaign,
    }));
    // Update the grid data with the new campaigns
    tableRef.current.api.applyTransaction({ update: updatedRows });
  };

  const updateTags = (newTags) => {
    const updatedRows = selectedPostRows.map(row => ({
      ...row,
      tags: newTags,
    }));
    // Update the grid data with the new tags
    tableRef.current.api.applyTransaction({ update: updatedRows });
  };
  const [isDataReady, setIsDataReady] = useState(false);

  React.useEffect(() => {
    setIsDataReady(!!selectedPostData && selectedPostData.length > 0);
    setRowData(getRowData());
  }, [selectedPostData]);

  const getRowData = React.useCallback(() => {
    if (!selectedPostData || selectedPostData.length === 0) return [];
    return selectedPostData.map((post, index) => ({
      id: post.id,
      avatar: post.data.userAvatar,
      thumbnail: post.data.thumbnail,
      account: post.data.creatorName,
      username: post.data.username,
      tags: post.data.tags,
      vibeScore: (() => {
        if (post.data.engagement && post.data.engagement.comments === 0) return 0;
        
        if (!post.reports || post.reports.length === 0) return 0;
        
        const latestReport = post.reports.reduce((latest, current) => 
          new Date(current.createdAt) > new Date(latest.createdAt) ? current : latest
        );
        
        return latestReport.vibeCheck ? latestReport.vibeCheck.vibeScore : 0;
      })(),
      platform: formatData(post.data.platform),
      postType: post.data.postType,
      date: formatFullDate(post.data.postedAt),
      engagementRate: (() => {
        let views = post.data.engagement.views ? post.data.engagement.views : 0;
        let comments = post.data.engagement.comments ? post.data.engagement.comments : 0;
        let likes = post.data.engagement.likes ? post.data.engagement.likes : 0;
        let shares = post.data.engagement.shares ? post.data.engagement.shares : 0;
        let saves = post.data.engagement.saves ? post.data.engagement.saves : 0;
        let platform = post.data.platform;
        let postType = post.data.postType;
        // console.log("platform", views, likes, comments, shares, saves, platform, postType);
        if (platform == "linkedin" 
          // || (platform == "instagram" && postType == "feed") 
          && comments >= 0 && likes >= 0 && shares >= 0 && saves >= 0) {
          views = estimateViews(likes, comments, shares, saves, platform);
          // console.log("views", views);
        }
      
        let rate = (((likes +
          comments +
          shares+saves) *
          100) /
       views);
      
        if (isNaN(rate) || !isFinite(rate)) {
          return -1;
        }
        else {
          return rate;
        }

      })(),
      views: post.data.engagement.views > 0 ? post.data.engagement.views : estimateViews(post.data.engagement.likes, post.data.engagement.comments, post.data.engagement.shares, post.data.engagement.saves, post.data.platform),
      comments: post.data.engagement.comments,
      likes: post.data.engagement.likes,
      shares: post.data.engagement.shares,
      saves: post.data.engagement.saves ? post.data.engagement.saves : 0,
      groupIds: post.groupIds ? post.groupIds : [],
      link: post.link ? post.link : "",
    }))
  }, [selectedPostData, teamData]);

  const gridOptions = useMemo(() => ({
    enableCellTextSelection: true,
    ensureDomOrder: true,
    suppressColumnVirtualisation: true,
    animateRows: false,
    rowHeight: isMobile ? 50 : 60,
  }), []);



  const columnDefs = useMemo(() => {
    return [
      // { field: "id", hide: ENV !== "dev", width:50 },
      {
        field: 'link',
        headerName: 'Link',
        cellRenderer: (params) => {
          return (
            <a href={params.value} target="_blank">
              Link
            </a>
          );
        },
        cellRendererParams: {
          target: '_blank',
        },
        width: 65
      },
      // {
      //   field: "post",
      //   headerName: "",
      //   headerCheckboxSelection: true,
      //   checkboxSelection: true,
      //   showDisabledCheckboxes: true,
      //   width:50
      // },

      {
        headerName: "",
        pinned: "left",
        sortable: false,
        cellRenderer: (props) => {
          return (
            <IconButton
              sx={{ height: 25, width: 25 }}
              onClick={() => {
                goToPost(props.data.id);
              }}
            >
              <OpenInNewOutlined
                sx={{ height: 20, width: 20 }}
              />
            </IconButton>
          );
        },
        width: 60,
      },
      {
        field: "account",
        cellStyle: { display: "flex", alignItems: "center" },
        valueFormatter: (params) => {
          //if same word is repeated while separated by multiple spaces, remove the duplicates

          return params.value.replace(/\s\s+/g, " ");
        },
        width: 200,
        cellRenderer: (props) => {
          return (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "1rem",
                  overflow: "hidden",
                  width: 200,
                }}
              >
                <Avatar
                  sx={{ width: 30, height: 30 }}
                  src={props.data.avatar}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography>
                    {props.data.account}
                  </Typography>
                  <Typography variant="body2">
                    @{props.data.username.replace("@", "")}
                  </Typography>
                </Box>
              </Box>
            </>
          );
        },
      },
      {
        field: "groupIds",
        headerName: "Campaigns",
        cellStyle: { display: "flex", alignItems: "center" },
  
        //if cell is clicked, update cell renderer
        valueFormatter: (params) => {
          return params.value.map((group) => {
            return teamData.groups.find(
              (g) => g.id == group
            ) ? teamData.groups.find((g) => g.id == group).name : "No Campaign";
          });
        },

        cellRenderer: (props) => {
          return  <Box sx={{width: 180}}>
          <GroupPicker
          data={{groupIds: props.data.groupIds}}
          refreshData={refreshData} softRefresh={softRefresh} {...props} teamData={teamData} cellView 
          postIds={[props.data.id]} />
          </Box>;
        },
        // },
        width: 180,
      },
      {
        field: "tags",
        headerName: "Tags",
        width: 180,
        cellStyle: { display: "flex", alignItems: "center" },
        valueFormatter: (params) => {
          return JSON.parse(params.value).map((tag) => tag);
        },
        cellRenderer: (props) => {
          return    <Box sx={{width: 180}}><ContentTagPicker teamData={teamData}
          refreshData={refreshData}
          post={{id: props.data.id, data:props.data}}
          softRefresh={softRefresh}
          cellView
          /></Box>
        },

      },
      {
        field: "vibeScore",
        cellStyle: { display: "flex", alignItems: "center" },
        cellRenderer: (props) => {
          return (props.data.comments > 0) ? (
            <MiniScorer score={props.data.vibeScore} />
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                position: "relative",
                p: 1,
              }}
            >
              <Tooltip title="Vibe Score is not available for posts with no comments.">
              <Typography
                align="center"
                sx={{
                  fontWeight: 600,
                  fontSize: 12,
                  color: "#888",
                }}
              >
                N/A
              </Typography>
              </Tooltip>
            </Box>
          );
        },
        width: 110,
      },
      {
        field: "platform",
        width: 110,
        cellRenderer: (props) => {
          return (
            <Typography variant="body1" fontWeight={600}>
              {props.data.platform}
            </Typography>
          );
        },
        cellStyle: { display: "flex", alignItems: "center" },
        width: 110,
      },
      {
        field: "engagementRate",
        valueFormatter: (params) => {
          return params.value < 0 ? "N/A" : params.value.toFixed(1) + "%";
        },
        cellRenderer: (props) => {
          // engagement rate as percentage
          return (
            //check if engagement rate is a number or is infinte
          
               <Typography variant={props.data.engagementRate < 0 ? "body2" : "body1"}
                 fontWeight={600} color={props.data.engagementRate < 0 ? "#888" : "#000"}>
              {props.data.engagementRate < 0 ? "N/A" : props.data.engagementRate.toFixed(1) + "%"}{props.data.platform == "LinkedIn" 
              // || (props.data.platform == "Instagram" && props.data.postType == "feed")
               ? "*" : ""}
              </Typography>
            
          );
        },
        cellStyle: { display: "flex", alignItems: "center" },
        width: 130,
         
        // cellRenderer: (props) => {
        //   return <MiniScorer score={props.data.vibeScore} />;
        // },
        // width:110
      },
      {
        field: "views",
        editable: true,
        cellRenderer: (props) => {
          return props.data.views == 0 || isNaN(props.data.views) || !isFinite(props.data.views)

           ? (
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: 14,
                color: "#888",
              }}
            >
              N/A
            </Typography>
          ) 

          : (
            <Typography>
              {formatNumber(props.data.views)}{props.data.platform == "LinkedIn" 
              // || (props.data.platform == "Instagram" && props.data.postType == "feed") 
              ? "*" : ""}
            </Typography>
          )
        
        },
        cellStyle: { display: "flex", alignItems: "center" },
        width: 110,
      },
      {
        field: "likes",
        cellRenderer: (props) => {
          return (  isNaN(props.data.likes) || !isFinite(props.data.likes)

          ? (
           <Typography
             sx={{
               fontWeight: 600,
               fontSize: 12,
               color: "#888",
             }}
           >
             N/A
           </Typography>
         ) : (
            <Typography>
              {formatNumber(props.data.likes)}
            </Typography>
         )
          );
        },
        cellStyle: { display: "flex", alignItems: "center" },
        width: 110,
      },
      {
        field: "comments",
        cellRenderer: (props) => {
          return (
            isNaN(props.data.comments) || !isFinite(props.data.comments)

            ? (
             <Typography
               sx={{
                 fontWeight: 600,
                 fontSize: 12,
                 color: "#888",
               }}
             >
               N/A
             </Typography>
           ) : (
              <Typography>
                {formatNumber(props.data.comments)}
              </Typography>
           )
          );
        },
        cellStyle: { display: "flex", alignItems: "center" },
        width: 110,
      },
      {
        field: "shares",
        cellRenderer: (props) => {
          return (
            isNaN(props.data.shares) || !isFinite(props.data.shares)

            ? (
             <Typography
               sx={{
                 fontWeight: 600,
                 fontSize: 12,
                 color: "#888",
               }}
             >
               N/A
             </Typography>
           ) : (
              <Typography>
                {formatNumber(props.data.shares)}
              </Typography>
           )
          );
        },
        cellStyle: { display: "flex", alignItems: "center" },
        width: 110,
      },
      {
        field: "saves",
        cellRenderer: (props) => {
          return (
            isNaN(props.data.saves) || !isFinite(props.data.saves)

            ? (
             <Typography
               sx={{
                 fontWeight: 600,
                 fontSize: 12,
                 color: "#888",
               }}
             >
               N/A
             </Typography>
           ) : (
              <Typography>
                {formatNumber(props.data.saves)}
              </Typography>
           )
          );
        },
        cellStyle: { display: "flex", alignItems: "center" },
        width: 110,
      },
      {
        field: "date",
        sortable: true,
        comparator: dateComparator,
        cellRenderer: (props) => {
          return <Typography>{props.data.date}</Typography>;
        },
        cellStyle: { display: "flex", alignItems: "center" },
        width: 110,
      },
    ]
  }, []);


 return(
  loading ? (
    <Box display="flex" justifyContent="center" alignItems="center" height={viewportHeight - (isMobile ? 200 : 300)}>
    <CircularProgress />
  </Box>
  ) : !isDataReady ? (
    <Box display="flex" justifyContent="center" alignItems="center" height={viewportHeight - (isMobile ? 200 : 300)}>
    <Typography>No data available</Typography>
  </Box>
  ) : (
    <div
    className={`ag-theme-quartz data-table`} // applying the grid theme
    // the grid will fill the size of the parent container
    style={{
      height: viewportHeight - (isMobile ? 200 : 350),
      width: "100%",
      background: "transparent",
      height: height
    }}
    
    id="data-table"
  >
     
    <AgGridReact
    // onGridReady={onGridReady}
    ref={tableRef}
    cacheQuickFilter={true}
    rowSelection="multiple"
    suppressRowClickSelection={true}
    suppressCellSelection={true} 
    suppressScrollOnNewData={true}
    deltaRowDataMode={true}
    quickFilterText={quickFilterText}
    enableRangeSelection={true} // Enable range selection for copying
    clipboardDeliminator={','}
    // onSelectionChanged={handleSelectionChanged}
    defaultCsvExportParams={
      {
        fileName: `Siftsy-Export-${new Date().toLocaleDateString()}`,
        columnSeparator: ",",
      
      }
    }
      gridOptions={gridOptions}
      rowData={rowData}
      columnDefs={columnDefs}
      rowBuffer={20}
        maxBlocksInCache={10}
        cacheOverflowSize={2}
        maxConcurrentDatasourceRequests={2}
        infiniteInitialRowCount={100}
        pagination={true}
        paginationPageSize={isMobile ? null : 100}
        suppressPaginationPanel={false}
        paginationAutoPageSize={isMobile ? true : false}
        paginationNumberFormatter={function(params) {
          return isMobile ? params.value : params.value.toLocaleString();
        }}
    
    />
  </div>
  
  )

  );

}


//create a popper with a selectchips component for when rows are selected in the table
//this will allow users to update the campaign of multiple posts at once


function SelectChipsPopper(props) {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const { selectedRows, teamData, refreshData, softRefresh, viewportWidth } = props;
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    setAnchorEl(null);
    //set anchorel to full window
    setAnchorEl(
      viewportWidth < 900
        ? document.body
        : document.getElementById("data-table")
    );
  }, []);


  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const popoverPosition = {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };

  return (
    <div>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
        disableEnforceFocus
        PaperProps={{
          sx: {
            borderRadius: 10,
            padding: 1,
            background: "#111",
            ...popoverPosition,
          },
        }}
      >
        <Box
          sx={{
            pointerEvents: "auto",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: 1,
          }}
        >
            <GroupPicker
              data={selectedRows}
              teamData={teamData}
              refreshData={refreshData}
              softRefresh={softRefresh}
              postId={[selectedRows.map((row) => row.id)]}
            />
          {/* <IconButton onClick={deselectAllComments}>
            <Close sx={{ color: "#fff" }} />
          </IconButton> */}
          <Typography
            variant="body1"
            sx={{ fontWeight: 700, color: "#fff" }}
          >{`${selectedRows.length} selected`}</Typography>

          <Box sx={{ ml: viewportWidth < 500 ? 1 : 3 }} />

          {/* {!allSelected ? (
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              sx={{ borderRadius: 12, position: "relative" }}
              onClick={selectAllComments}
            >
              Select all
            </Button>
          ) : null} */}
{/* 
          {viewportWidth < 500 ? (
            <Tooltip title={numSelected < 20 ? "Export Comments As Image" : "Select less than 20 comments to export."}>
              <span>
            <IconButton
              onClick={goToExport}
              disabled={numSelected === 0 || numSelected > 20}
              sx={{ color: "#fff", pl: 2, pr: 2 }}
            >
              <IosShareOutlined />
            </IconButton>
            </span>
            </Tooltip>
          ) : (
            <Tooltip title={numSelected < 20 ? "Export Comments As Image" : "Select less than 20 comments to export."}>
              <span>
            <Button
            disabled={numSelected === 0 || numSelected > 20}
              onClick={goToExport}
              sx={{ color: "#fff", pl: 2, pr: 2 }}
              endIcon={<IosShareOutlined />}
            >
              Export{" "}
            </Button>
            </span>
            </Tooltip>
          )} */}
        </Box>
      </Popover>
    </div>
  );
}
